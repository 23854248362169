.App-logo {
  height: 40vmin;
  pointer-events: none;
}

div.recipe,
div.recipe div {
  padding: 10px;
}

.recipeList {
  max-width: 100vw;
}

@media (min-width: 916px) {
  .recipeList {
    max-width: inherit;
  }
}

.filterArea {
  top: 0;
  position: fixed;
  z-index: 1;
  height: 50px;
  width: 100%;
  max-width: inherit;
}

.filterAreaWrapper {
  height: 50px;
  width: 100%;
  max-width: inherit;
}

#nameFilter {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  font-size: 16px;
}

.recipeTileWrapper {
  box-sizing: border-box;
  overflow: auto;
}

.recipeTile {
  position: relative;
  padding-top: 31%;
  margin: 0 auto;
  width: 700px;
  max-width: 100%;
  background-position: center center;
}

.recipeTile .name {
  color: white;
  font-weight: bold;
  position: absolute;
  bottom: 16px;
  left: 16px;
}

html {
  background-color: #f0f0f0;
}

.App {
  box-shadow: 0px 0px 20px #c0c0c0;
  margin: auto;
  width: 900px;
  max-width: 900px;
  background-color: #ffffff;
}

.recipe {
  width: 100%;
  box-sizing: border-box;
}

.recipeImage {
  width: 100%;
}

.title {
  font-family: Freeletics, Arial, Helvetica, sans-serif;
  color: white;
}

thead td {
  font-weight: bold;
}

.recipeName {
  font-weight: bold;
  font-size: 20pt;
}

table {
  border-collapse: collapse;
}

tbody tr:nth-of-type(even) {
  background: #e0e0e0;
}

tbody th {
  font-weight: normal;
  text-align: left;
}

td {
  border: 0px solid black;
  margin: 0;
  padding: 2px 5px 2px 5px;
}

.recipeImage {
  max-height: 540px;
}

col {
  text-align: left;
}

thead th.amount {
  width: 70px;
}

tbody td {
  text-align: right;
}
tbody td:first-child {
  text-align: center;
}

td.wholeNumber {
  text-align: right;
  padding-right: 0px;
  padding-left: 20px;
}

td.fractionNumber {
  text-align: left;
  padding-left: 0px;
}

td.unit {
  text-align: left;
}

@font-face {
  font-family: "Freeletics";
  src: url("FreeleticsTheSans.ttf");
}
